import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    freeSim: '2GB Europe for 15 Days',
                    freeSimFirstLine: 'Redeem your free eSIM and get',
                    freeSimSecondLine: 'connected as soon as you land',
                    redeemNow: 'Redeem Now',
                    enterPhoneHeader: 'Enter your phone number',
                    enterPhoneDescription: 'Please enter your phone number',
                    enterPhone: 'Phone Number',
                    verifyPhoneHeader: 'Verify your phone number',
                    enterPhoneVerificationCode: 'Enter your phone verification code',
                    enterEmailHeader: 'Enter your email',
                    enterEmailDescription: 'Please enter your email address',
                    enterEmail: 'Email',
                    nextButton: 'Next',
                    emailIncorrect: 'The email provided is incorrect',
                    chooseDestination: 'Choose your destination',
                    searchDestination: 'Search for your destination',
                    localText: 'Local',
                    regionalText: 'Regional',
                    viewAllText: 'view all',
                    successVoilaText: 'Voila! Your 2GB Europe eSIM was just sent to you at',
                    stepsText: 'Steps to access your eSIM',
                    stepOnePart: '1- Download the',
                    stepOneApp: 'Simly App',
                    stepOneLoginCredentials: 'and login with',
                    stepOneEmail: 'as your email',
                    stepOneAnd: 'and',
                    stepOnePassword: 'as your password.',
                    stepTwoPart: '2- Go to ‘My eSIMs’ to find your eSIM then tap on ‘Install’ and follow the instructions.',
                    downloadText: 'Download App',
                    alreadyRedeemed: 'Already Redeemed',
                    emailAlreadyUsed: 'The email provided has already redeemed a QR Code!',
                    qrCodeRedeemed: 'That QR code has already been redeemed. Try another one!',
                    loading: 'Loading..',
                    pageNotFound: 'Page Not Found',
                    pageNotFoundDescription: 'The page you are looking for does not exist or has been moved.',
                    areYouSure: 'Are you sure?',
                    areYouSureDetails: 'Are you sure you want to redeem your eSIM for this destination?',
                    no: "No",
                    yes: "Yes",
                    alreadyRedeemedPhone: 'This phone number already redeemed the free 2GB!',
                    waitForConfirmation: 'You need to wait before you can try again.',
                    invalidConfirmationCode: 'You provided an invalid confirmation code.',
                    regionLocked: 'We\'re sorry, this campaign is not available in your region!',
                    termsAndConditions: 'Terms & Conditions',
                    termsAndConditionsIntroduction: 'This document lays out the Terms and Conditions which shall apply to all the Simly accounts which are existing or may be opened anytime in the future.',
                    termsAndConditionsEntryTitle: '1. Entry/Eligibility',
                    termsAndConditionsEntryFirstSection: '1.1. To be eligible for this promotion, the user must create or already have an account on Simly.',
                    termsAndConditionsEntryDisqualify: '1.2. Entries can be disqualified at the discretion of the Promoter if they have already redeemed the free eSIM and benefited from this promotion.',
                    promotionProceduresTitle: '2. Promotion Procedures',
                    promotionProcedure1: '2.1. The customer has to redeem their free eSIM through the co-branded landing page promoted by Shahid.',
                    promotionProcedure2: '2.2. The free eSIM will be generated after entering email and destination. The free eSIM can only be redeemed once and only for the listed countries.',
                    promotionProcedure3: '2.3. The promotion cannot be combined or used alongside any other promotion.',
                    validityTitle: '3. Validity/Duration/Extensions',
                    validity1: '3.1. The promotion is limited to the first redemptions.',
                    validity2: '3.2. The promotion is valid from 13/06/2024 until 14/10/2024. The promotion might stop sooner if the maximum number of redemptions is reached before the end date.',
                    cancellationsTitle: '4. Cancellations/Refunds',
                    cancellation1: '4.1. Simly reserves the right to cancel or modify any redemption, or revoke the use of this promotion for any of the following reasons:',
                    cancellation2: '4.1.1. Free eSIM was not redeemed within the stated period or the maximum number of redemptions has been reached.',
                    cancellation3: '4.1.2. Suspicious or fraudulent activity.',
                    cancellation4: '4.1.3. Promotion abuse, including the use of multiple accounts or multiple checkouts associated with the same customer or group of customers.',
                    cancellation5: '4.1.4. Free eSIM used in bad faith (including resold eSIMs or use of free eSIM by customers purchasing products for re-selling).',
                    standardTermsTitle: '5. Standard Terms and Conditions',
                    standardTerm1: '5.1. By entering this campaign, you accept and agree to be bound by these Terms and Conditions and acknowledge that you have read and understood these Terms and Conditions.',
                    standardTerm2: '5.2. The Promoter reserves the right to change these terms & conditions or cancel any promotions at any time and without prior notice in the best interests of the company.',
                    standardTerm3: '5.3. No entrants shall have the right to contest any decision by the Promoter concerning any aspect of the campaign or the interpretation of the Terms and Conditions or the determination as to the qualification of entries. The Promoter\'s decision is final and no correspondence will be entered into.',
                    standardTerm4: '5.4. If there is a dispute as to the identity of an entrant, the Promoter reserves the right, in its sole discretion, to determine the identity of the entrant.',
                    standardTerm5: '5.5. Any cost associated with accessing the promotional website is the entrant\'s responsibility and is dependent on the Internet service provider used. The Promoter will not be liable to compensate expenses incurred in making an entry where applicable. No refund will be made for the cost of any entry where applicable.',
                    poweredBy: 'Powered by',
                    andText: "and",
                    screenshotPage: "Screenshot this page to save the credentials",
                    alreadyRedeemedExplanation: 'This promo code has already been redeemed.'
                },
            },
            ar: {
                translation: {
                    freeSim: '1GB eSIM مجانًا',
                    freeSimFirstLine: 'استبدل 1 جيجابايت مجاناً واحصل',
                    freeSimSecondLine: 'على اتصال فوري عند وصولك',
                    redeemNow: 'استبدل الآن',
                    enterPhoneHeader: 'أدخل رقم هاتفك',
                    enterPhoneDescription: 'يرجى إدخال رقم هاتفك',
                    enterPhone: 'رقم الهاتف',
                    verifyPhoneHeader: 'تحقق من رقم هاتفك',
                    enterPhoneVerificationCode: 'أدخل رمز التحقق من الهاتف',
                    enterEmailHeader: 'أدخل بريدك الإلكتروني',
                    enterEmailDescription: 'الرجاء إدخال عنوان بريدك الإلكتروني',
                    enterEmail: 'البريد الإلكتروني',
                    nextButton: 'التالي',
                    emailIncorrect: 'البريد الإلكتروني الذي تم تقديمه غير صحيح',
                    chooseDestination: 'اختر وجهتك',
                    searchDestination: 'ابحث عن وجهتك',
                    localText: 'محلي',
                    regionalText: 'إقليمي',
                    viewAllText: 'عرض الكل',
                    successVoilaText: 'فويلاء! تم إرسال eSIM الخاص بك 1 جيجابايت مجانًا إليك في',
                    stepsText: 'خطوات للوصول إلى eSIM الخاص بك',
                    stepOnePart: '1- قم بتنزيل تطبيق',
                    stepOneApp: 'Simly',
                    stepOneLoginCredentials: 'وقم بتسجيل الدخول باستخدام',
                    stepOneEmail: 'كالبريد الإلكتروني الخاص بك',
                    stepOneAnd: 'و',
                    stepOnePassword: 'ككلمة المرور الخاصة بك.',
                    stepTwoPart: '2- اذهب إلى "eSIMs الخاصة بي" للعثور على شريحة eSIM الخاصة بك ثم اضغط على "تثبيت" واتبع التعليمات.',
                    downloadText: 'قم بتنزيل التطبيق',
                    alreadyRedeemed: 'تم استبدالها',
                    emailAlreadyUsed: 'البريد الإلكتروني الذي تم توفيره قد تم تبديله بالفعل بواسطة رمز الاستجابة السريعة!',
                    qrCodeRedeemed: 'تم استبدال رمز الاستجابة السريعة هذا بالفعل. جرب رمزًا آخر!',
                    loading: 'جاري التحميل...',
                    pageNotFound: 'الصفحة غير موجودة',
                    pageNotFoundDescription: 'الصفحة التي تبحث عنها غير موجودة أو تم نقلها.',
                    areYouSure: 'هل أنت متأكد أنك تريد استرداد بطاقة eSIM الخاصة بك لهذه الوجهة؟',
                    areYouSureDetails: 'هل تريد المتابعة في شراء eSIM؟',
                    no: "لا",
                    yes: "نعم",
                    alreadyRedeemedPhone: 'هذا الرقم قام بالفعل بتحصيل 1 جيجابايت مجانًا!',
                    alreadyRedeemedExplanation: 'لقد تم استرداد هذا الرمز الترويجي.',
                    waitForConfirmation: 'يجب عليك الانتظار قبل أن تتمكن من المحاولة مرة أخرى.',
                    invalidConfirmationCode: 'لقد قدمت رمز تأكيد غير صالح.',
                    regionLocked: 'نأسف، هذه الحملة غير متوفرة في منطقتك!',
                    termsAndConditions: 'الشروط و الأحكام',
                    termsAndConditionsIntroduction: 'يحدد هذا المستند الشروط والأحكام التي تنطبق على جميع حسابات Simly الحالية أو التي قد يتم فتحها في أي وقت في المستقبل.',
                    termsAndConditionsEntryTitle: '١. الدخول / الأهلية',
                    termsAndConditionsEntryFirstSection: '١.١ ليكون المستخدم مؤهلاً لهذا العرض الترويجي، يجب عليه إنشاء حساب أو أن يكون لديه حساب بالفعل على Simly.',
                    termsAndConditionsEntryDisqualify: '١.٢ يمكن للمنظم استبعاد المشاركات وفقًا لتقديره إذا كانوا قد استردوا بالفعل eSIM المجاني واستفادوا من هذا العرض الترويجي.',
                    promotionProceduresTitle: '٢. إجراءات العرض الترويجي',
                    promotionProcedure1: '٢.١ يجب على العميل استرداد eSIM المجاني الخاص به من خلال الصفحة المخصصة التي يتم الترويج لها من قبل شاهد.',
                    promotionProcedure2: '٢.٢ سيتم إنشاء eSIM المجاني بعد إدخال البريد الإلكتروني والوجهة. يمكن استرداد eSIM المجاني مرة واحدة فقط وللبلدان المدرجة فقط.',
                    promotionProcedure3: '٢.٣ لا يمكن دمج العرض الترويجي أو استخدامه بجانب أي عرض ترويجي آخر.',
                    validityTitle: '٣. الصلاحية / المدة / التمديدات',
                    validity1: '٣.١ العرض الترويجي محدود على الاستردادات الأولى.',
                    validity2: '٣.٢ العرض الترويجي صالح من 13/06/2024 حتى 14/10/2024. قد يتوقف العرض الترويجي قبل الموعد النهائي إذا تم الوصول إلى الحد الأقصى لعدد الاستردادات.',
                    cancellationsTitle: '٤. الإلغاءات / الاستردادات',
                    cancellation1: '٤.١ تحتفظ Simly بالحق في إلغاء أو تعديل أي استرداد، أو إلغاء استخدام هذا العرض الترويجي لأي من الأسباب التالية:',
                    cancellation2: '٤.١.١ لم يتم استرداد eSIM المجاني خلال الفترة المذكورة أو تم الوصول إلى الحد الأقصى لعدد الاستردادات.',
                    cancellation3: '٤.١.٢ النشاط المشبوه أو الاحتيالي.',
                    cancellation4: '٤.١.٣ إساءة استخدام العرض الترويجي، بما في ذلك استخدام حسابات متعددة أو عمليات شراء متعددة مرتبطة بنفس العميل أو مجموعة العملاء.',
                    cancellation5: '٤.١.٤ استخدام eSIM المجاني بسوء نية (بما في ذلك بيع eSIM المجاني أو استخدامه من قبل العملاء الذين يشترون المنتجات لإعادة بيعها).',
                    standardTermsTitle: '٥. الشروط والأحكام القياسية',
                    standardTerm1: '٥.١ بدخولك في هذه الحملة، تقبل وتوافق على الالتزام بهذه الشروط والأحكام وتقر بأنك قد قرأت وفهمت هذه الشروط والأحكام.',
                    standardTerm2: '٥.٢ يحتفظ المنظم بالحق في تغيير هذه الشروط والأحكام أو إلغاء أي عروض ترويجية في أي وقت ودون إشعار مسبق بما يخدم مصلحة الشركة.',
                    standardTerm3: '٥.٣ لا يحق لأي مشارك الطعن في أي قرار من قبل المنظم فيما يتعلق بأي جانب من جوانب الحملة أو تفسير الشروط والأحكام أو تحديد أهلية المشاركات. قرار المنظم نهائي ولن يتم الدخول في أي مراسلات بشأنه.',
                    standardTerm4: '٥.٤ إذا كان هناك نزاع حول هوية المشارك، يحتفظ المنظم بالحق، وفقًا لتقديره الخاص، في تحديد هوية المشارك.',
                    standardTerm5: '٥.٥ أي تكاليف مرتبطة بالوصول إلى الموقع الترويجي هي مسؤولية المشارك وتعتمد على مزود خدمة الإنترنت المستخدم. لن يكون المنظم مسؤولاً عن تعويض النفقات التي تكبدتها المشاركة في الحملة حيثما ينطبق. لن يتم استرداد أي تكاليف للمشاركة حيثما ينطبق.',
                    poweredBy: 'بواسطة',
                    andText: "و",
                    screenshotPage: "قم بتصوير هذه الصفحة لحفظ بيانات الاعتماد"
                },
            },
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
